/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyInfo } from '../models/MyInfo';
import type { ValidationTest } from '../models/ValidationTest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SuperSecretService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns MyInfo Success
     * @throws ApiError
     */
    public myInfoSuperSecret(): CancelablePromise<MyInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/super-secret/my-info',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public adminRoleOnlySuperSecret(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/super-secret/admin-role-only',
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public testValidationSuperSecret({
        myQueryEmail,
        requestBody,
    }: {
        myQueryEmail: string,
        requestBody: ValidationTest,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/super-secret/validation-test',
            query: {
                'myQueryEmail': myQueryEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
